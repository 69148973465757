<template>
  <div>
    1
    <div class="page-content">
      <div>
        普通模式
        <upload @finishUpload="finishAll1"> </upload>
      </div>
      <div>
        限制文件大小 maxSize="10" 最大上传文件 10M
        <upload :maxSize="10" @finishUpload="finishAll2"> </upload>
      </div>
      <div>
        最多上传两个文件 maxFiles="2"
        <upload :maxSize="10" :maxFiles="5" @finishUpload="finishAll3">
        </upload>
      </div>
      <div>
        初始化图片赋值：
        <upload
          :maxSize="10"
          :maxFiles="5"
          :value="d.file1Ids"
          @finishUpload="finishAll4"
        >
        </upload>
      </div>
      <div>
        只读模式 readOnly="true"（只显示，不能添加和删除）
        <upload
          :maxSize="10"
          :readOnly="true"
          :value="d.file2Ids"
          @finishUpload="finishAll5"
        >
        </upload>
      </div>
    </div>
    <div>
      2
      <upload :maxSize="10" @finishUpload="finishAll1"> </upload>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      bNew: true,
      formdata: {
        id: "",
        name: "",
      },
      d: {},
    };
  },
  created() {
    // this.d = {
    //   file1Ids: [
    //     "3a027416-e338-8653-0440-06f29b1afec2",
    //     "3a027417-0c8f-7af4-a564-f865f7df4870",
    //     "3a027417-17bc-a73c-4e08-78907a3f1e4b",
    //   ],
    //   file2Ids: [
    //     "3a0273fe-d4ac-0562-2c25-d8fe7b403b96",
    //     "3a019d77-cfaa-59db-a394-663817950d9a",
    //     "3a019d9a-e3a3-c464-8cc5-ec810b0d711a",
    //   ],
    // };
  },
  mounted() {
    this.d.file1Ids = [
      "3a027416-e338-8653-0440-06f29b1afec2",
      "3a027417-0c8f-7af4-a564-f865f7df4870",
      "3a027417-17bc-a73c-4e08-78907a3f1e4b",
    ];

    // let id=this.$route.params.id;
    // console.log(this.$route.query.id)
    // if (id!=undefined){
    // }
  },
  methods: {
    // save(){
    //   if (!bNew){
    //     修改
    //   }
    //   else{
    //     新增
    //   }
    // },
    //文件上传成功后返回的文件组
    finishAll1(e) {
      console.log(e);
    },
    finishAll2(e) {
      console.log(e);
    },
    finishAll3(e) {
      console.log(e);
    },
    finishAll4(e) {
      console.log(e);
    },
    finishAll5(e) {
      console.log(e);
    },
  },
};
</script>